import { DefaultEventsMap } from '@socket.io/component-emitter';
import { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { listeners } from '../socket/listeners';
import { UserProps } from '../context/types';

const react_socket_identifier: string =
  process.env.REACT_APP_SOCKECT_PLAYER_IDENDENTIFIER || '';

const socket_server_base_url: string =
  process.env.REACT_APP_SOCKET_SERVER_BASE_URL || '';

export const useSocket = (
  setSocket: React.Dispatch<
    React.SetStateAction<Socket<DefaultEventsMap, DefaultEventsMap> | null>
  >,
  readyToConect: boolean,
  token: string,
  setStatus: (status: 'O' | 'W' | 'R' | 'P') => void,
  setFeedback: React.Dispatch<
    React.SetStateAction<'finished' | 'error' | 'timeover' | ''>
  >,
  userId: string,
  updateUserInfo: <T extends keyof UserProps>(
    fieldName: T,
    newValue: UserProps[T]
  ) => void,
  setPlays: React.Dispatch<React.SetStateAction<number>>,
  startGame: () => void
) =>
  useEffect(() => {
    if (!readyToConect) return;
    const newSocket = io(socket_server_base_url, {
      extraHeaders: {
        'x-client-id': react_socket_identifier,
        'x-client-key': token,
      },
    });

    setSocket(() => newSocket);
    listeners(
      newSocket,
      setStatus,
      setFeedback,
      setPlays,
      userId,
      updateUserInfo,
      startGame
    );

    return () => {
      newSocket.disconnect();
    };
  }, [
    setSocket,
    readyToConect,
    setStatus,
    token,
    setFeedback,
    userId,
    updateUserInfo,
    setPlays,
    startGame,
  ]);
