import * as S from './styles';

export function PrivacyPolicy() {
  return (
    <S.Content>
      <h2>Políticas de Uso e de Privacidade onlineMachine</h2>
      <h3>Termos de uso</h3>
      <h4>1. Aceitação dos Termos</h4>
      <span>
        Bem-vindo (a) a onlineMachine! Ao acessar ou utilizar nosso Website e
        aplicativo, você concorda em cumprir nossas diretrizes e estar sujeito a
        estes Termos de Uso. Este documento estabelece as regras e condições
        para o uso do Website e Aplicativo de jogo. É proibida a violação dos
        direitos autorais ou qualquer uso impróprio do jogo e Aplicação que
        constituem esta plataforma.
      </span>
      <h4>2. Alteração nos termos</h4>
      <span>
        Reservamo-nos o direito de modificar estes Termos de Uso a qualquer
        momento. Quaisquer alterações serão publicadas nesta página e a
        continuidade do uso do Website ou aplicativo após a publicação das
        mudanças implica e compreende-se a aceitação dos novos termos.
      </span>
      <h4>3. Elegibilidade</h4>
      <span>
        Para utilizar este website e/ou aplicativo você deve ter pelo menos 13
        anos de idade. Usuários menores de 18 anos devem obter permissão dos
        pais ou responsáveis para usar o website ou aplicativo.
      </span>
      <h4>4. Registro de segurança da conta</h4>
      <span>
        Para utilizar os recursos que esta aplicação oferece é necessário criar
        uma conta. Você está ciente que é responsável por manter a
        confidencialidade de suas informações de login e por todas as atividades
        que ocorram na sua conta. Notifique a onlineMachine imediatamente em
        caso de uso não autorizado da sua conta.
      </span>
      <h4>5. Uso aceitável</h4>
      <span>
        O usuário concorda em utilizar esta plataforma para o fim a que ela
        destina-se, tem ciência que não é aceitável qualquer outra utilização.
        Não serão tolerados qualquer finalidade ilegal ou não autorizada, a
        exemplo, mas não limitada a atividades que interferiram ou interrompam o
        funcionamento da aplicação, ou que envolvam assédiar, ameaçar ou
        prejudicar outros usuários.
      </span>
      <h4>6. Conversas</h4>
      <span>
        É proibido o uso de palavras ofensivas ou em contextos ofensivos em
        conversas dentro da plataforma, o que é passível de advertência,
        bloqueios ou mesmo exclusão da conta, como também, a retirada do texto
        ou termo de forma parcial ou completa. É importante considerar que a
        lista de palavras e expressões que possam ser consideradas inapropriadas
        é abrangente e cada caso será considerado em seu contexto individual.
        Podem ser considerados como contextos ofensivos, contudo, não limita-se
        a eles, as Palavras de baixo calão; Palavrões comuns: Palavra com
        conotação sexual explícita ou Termos pejorativos para partes do corpo ou
        funções corporais; Ofensas raciais, étnicas ou religiosas: Termos que
        discriminam ou ofendem com base em raça, etnia, religião ou
        nacionalidade; Insultos e xingamentos: Palavras que denotam raiva ou
        desrespeito, como insultos pessoais; Termos relacionados a violência e
        abuso: Palavras que descrevem ou incentivam violência, abuso ou
        agressão; Gírias e expressões vulgar: Variedades de gírias que podem ser
        consideradas inapropriadas dependendo do contexto; Termos de ódio e
        discriminação: Palavras que transmitem ódio ou desrespeito contra grupos
        específicos de pessoas.
      </span>
      <h4>7. Propriedade Intelectual</h4>
      <span>
        Todos os direitos, títulos e interesses relativos à aplicação,
        incluindo, mas não se limitando a, conteúdo, gráficos, interface e
        código, são propriedade exclusiva da onlineMachine. Não é permitido
        copiar ou reproduzir, modificar, vender ou alugar qualquer parte sem a
        nossa autorização.
      </span>
      <h4>8. Compras de jogadas</h4>
      <span>
        A onlineMachine oferece compras de jogadas para que se possa jogar
        dentro da plataforma. Todas as compras são finais e não reembolsáveis,
        exceto conforme exigido pela lei aplicável.
      </span>
      <h4>9. Pagamento</h4>
      <span>
        A plataforma aceita pagamento via formas disponíveis no momento da
        finalização da solicitação. É aceito valores a partir de R$2,00 (dois
        reais).
      </span>
      <h4>10. Prêmio</h4>
      <span>
        São duas as formas para o resgate do prêmio oferecidas: o envio da
        pelúcia para um endereço indicado pelo jogador a partir de uma
        solicitação e a troca da pelúcia por saldo para jogar. Após escolher e
        finalizar uma das duas formas para resgate do prêmio, a ação não poderá
        ser desfeita. O valor adicionado à carteira, resultado da conversão de
        pelúcia para saldo em virtude da troca de pelúcia por saldo para jogar,
        deverá ser utilizado para o único fim de jogadas dentro da plataforma e
        não poderá ser requerido em caso de encerramento de conta. As pelúcias
        capturadas ficam disponíveis para resgate na conta do usuário. As contas
        que ficarem inativas por um período igual ou superior a 30 dias e
        possuírem pelúcias capturadas, ao solicitarem resgate serão enviadas as
        pelúcias disponíveis em estoque no momento da solicitação. O número
        máximo de pelúcias permitido acumular em conta é de 25, ao atingir 25
        pelúcias em conta é obrigatório o resgate por um dos dois meios
        disponíveis para esse fim. O frete grátis a partir de 20 pelúcias é uma
        cortesia da OnlineMachine.com.br, está sujeita a alterações sem aviso
        prévio. O resgate do prêmio é responsabilidade do jogador. A captura da
        pelúcia depende das habilidades do jogador, pesquise e se informe sobre
        as técnicas de jogadas.
      </span>
      <h4>11. Prazo</h4>
      <span>
        O prazo para a entrega de pelúcias após a solicitação de resgate é
        informado no momento do cálculo de frete e depende da prestadora de
        serviço responsável. A confirmação de pagamento do frete pode
        influenciar no prazo final que será informado por e-mail após a
        Instituição Financeira confirmar o pagamento. O prazo e condições para
        resgate do prêmio é o estabelecido na seção Prêmio.
      </span>
      <h4>12. Reembolso de jogadas</h4>
      <span>
        As jogadas selecionadas ao entrar na fila e não finalizadas são
        reembolsadas automaticamente em até 48 horas úteis após o jogador ser
        desconectado por motivo da máquina entrar em abastecimento ou manutenção
        durante o seu tempo de jogo, ou por inatividade do jogador. Se o
        reembolso for necessário por qualquer outro motivo, o jogador deve
        contatar nosso suporte que irá analisar individualmente a sua
        solicitação e o prazo para resposta é de até 5 dias úteis. Jogadas
        perdidas por desconexão em consequência de baixa qualidade no serviço da
        rede de Internet do jogador não serão reembolsadas. Antes de entrar na
        fila é necessário certificar-se da estabilidade na sua rede de Internet.
      </span>
      <h4>13. Cancelamento e Encerramento</h4>
      <span>
        Reservamo-nos o direito de suspender ou encerrar a conta e acesso ao
        website e aplicativo a qualquer momento, sem aviso prévio, se for
        observado que o usuário violou esta Política de Uso e Privacidade.
      </span>
      <h4>14. Limitação de Responsabilidade</h4>
      <span>
        Na extensão máxima permitida pela lei aplicável, onlineMachine.com.br
        não será responsável por quaisquer danos indiretos, acidentais,
        consequenciais ou punitivos decorrentes do mau uso ou incapacidade de
        usar o website ou aplicativo.
      </span>
      <h4>15. Indenização</h4>
      <span>
        Você concorda em indenizar, defender e isentar a onlineMachine de
        qualquer reclamação, responsabilidade, dano ou despesa decorrente de sua
        violação a esta Política de Uso e Privacidade ou, do uso inadequado do
        Website e aplicativo.
      </span>
      <h4>16. Lei Aplicável</h4>
      <span>
        Estes Termos de Uso serão regidos e interpretados de acordo com as leis
        de São Paulo/Brasil e a LGPD, sem considerar os conflitos de disposições
        legais.
      </span>
      <h4>17. Contato</h4>
      <span>
        Em caso de dúvidas sobre estes Termos de Uso, entre em contato conosco
        através do endereço de e-mail:{' '}
        <a href="mailto:sac@onlinemachine.com.br">sac@onlinemachine.com.br</a>.
      </span>
      <h4>18. Disposições Gerais</h4>
      <span>
        Se qualquer parte destes Termos de Uso for considerada inválida ou
        inexequível, essa parte será interpretada de acordo com a lei aplicável,
        e as partes restantes permanecerão em pleno vigor de efeito.
      </span>

      <h3>Política de privacidade</h3>
      <h4>1. Introdução</h4>
      <span>
        Esta política de privacidade descreve como a onlineMachine.com.br
        coleta, usa, armazena e protege as informações pessoais dos usuários do
        nosso website e aplicativo de jogo. Ao utilizar o nosso Website e
        aplicativo, você concorda com a coleta e o uso das informações de acordo
        com esta política.
      </span>
      <h4>2. Informações coletadas</h4>
      <span>
        Para proporcionar a melhor experiência de jogo, coletamos informações
        pessoais, como apelido, idade, CPF e endereço de e-mail. Esses dados são
        necessários para criar e gerenciar sua conta no site seguindo as
        diretrizes da LGPD. O endereço e telefone são informados e utilizados
        para entrega do prêmio. Coletamos também informações do dispositivo,
        como tipo de dispositivo e endereço IP para garantir a segurança e
        identificação do usuário.
      </span>
      <h4>3. Uso das Informações</h4>
      <span>
        As informações pessoais coletadas são utilizadas para personalizar sua
        experiência de jogo e fornecer suporte ao cliente. Como também gerenciar
        sua conta, personalizar a experiência do usuário, melhorar as
        funcionalidades da aplicação, monitorar e prevenir fraudes ou outras
        atividades ilegais e proteger a segurança de nossos usuários.
      </span>
      <h4>4. Compartilhamento das Informações</h4>
      <span>
        A onlineMachine compartilha as suas informações nas seguintes
        circunstâncias: com provedores de serviços que realizam funções em seu
        nome, como hospedagem de servidores, análise de dados e suporte ao
        cliente. Em conformidade legal, quando exigido por lei para proteger
        seus direitos, segurança e propriedade, ou o de seus usuários e público.
        Em caso de fusão, aquisição ou venda de ativos, suas informações podem
        ser transferidas como parte da transação.
      </span>
      <h4>5. Armazenamento e Segurança das Informações</h4>
      <span>
        Implementamos medidas de segurança avançadas para proteger suas
        informações pessoais contra acesso não autorizado e uso indevido. No
        entanto, nenhum método de transmissão pela internet ou armazenamento
        eletrônico é 100% seguro, e não podemos garantir a segurança absoluta.
      </span>
      <h4>6. Confidencialidade</h4>
      <span>
        Suas informações pessoais são tratadas de forma confidencial e são
        armazenadas em servidores seguros.
      </span>
      <h4>7. Seus Direitos</h4>
      <span>
        Você tem o direito de solicitar acesso às suas informações pessoais e
        corrigir quaisquer dados imprecisos. Pedir a exclusão da sua conta e
        informações pessoais, sujeito a certas exceções conforme a lei
        aplicável. Optar por não receber comunicações promocionais seguindo as
        instruções de cancelamento de inscrição em nossos e-mails.
      </span>
      <h4>8. Retenção dos Dados</h4>
      <span>
        As informações pessoais serão retidas apenas pelo tempo necessário para
        cumprir os propósitos mencionados nesta política, a menos que exigido
        por lei.
      </span>
      <h4>9. Cookies de Rastreamento</h4>
      <span>
        Utilizamos cookies e tecnologias de rastreamento para melhorar sua
        experiência de jogo. Os cookies são pequenos arquivos que são
        armazenados no seu dispositivo e ajudam a lembrar suas preferências e
        atividades anteriores, ao fazer login você concorda com o uso dos
        cookies.
      </span>
      <h4>10 .Privacidade Infantil</h4>
      <span>
        Nosso website e aplicativo não é destinado a menores de 16 anos. Como
        medida de segurança, os dados de cadastro são validados junto a órgãos
        competentes.
      </span>
      <h4>11. Responsabilidade Legal</h4>
      <span>
        Embora tomemos todas as precauções necessárias para proteger suas
        informações pessoais, não podemos garantir a segurança absoluta da
        transmissão de dados pela Internet. Ao concordar com estes termos, você
        reconhece a necessidade de utilizar uma senha segura e não
        compartilhá-la com terceiros. A senha é pessoal e não nos
        responsabilizamos pelas consequências decorrentes do compartilhamento.
      </span>
      <h4>12. Alterações a esta Política de Privacidade</h4>
      <span>
        Podemos atualizar esta Política de Privacidade periodicamente.
        Notificamos sobre quaisquer alterações publicando a nova política nesta
        página. Recomendamos que você revise esta política regularmente para se
        manter informado sobre nossasnpráticas de privacidade.
      </span>
      <h4>13. Contato</h4>
      <span>
        Se você tiver dúvidas ou preocupações sobre esta Política de
        Privacidade, entre em contato conosco através do endereço de e-mail:{' '}
        <a href="mailto:sac@onlinemachine.com.br">sac@onlinemachine.com.br</a>.
      </span>
      <span>
        Obrigado por escolher a onlineMachine.com.br! Aproveite sua experiência
        de jogo.
      </span>
    </S.Content>
  );
}

export default PrivacyPolicy;
