/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext } from 'react';
import { MachineContext } from '../../../context';
import { isMobileDevice } from '../../../utils';

import * as S from './styles';
import { DirectionalButtonProps } from './types';

export function DirectionalButton({
  command,
  children,
}: DirectionalButtonProps) {
  const {
    status,
    socket,
    moved,
    setMoved,
    isActive,
    plays,
    feedback,
    seconds,
    videoIsLoaded,
    user,
  } = useContext(MachineContext);

  const allowCommand =
    status === 'P' &&
    plays &&
    feedback === '' &&
    seconds !== 0 &&
    videoIsLoaded &&
    isActive;

  const sendCommand = useCallback(() => {
    if (socket && socket.connected && allowCommand) {
      if (!moved) {
        setMoved(true);
      }

      socket.emit('command', {
        button: command,
        command: 1,
        username: user.name,
      });
    }
  }, [socket, allowCommand, moved, command, user.name, setMoved]);

  const cancelCommand = useCallback(() => {
    if (socket && socket.connected && allowCommand) {
      socket.emit('command', {
        button: command,
        command: 0,
        username: user.name,
      });
    }
  }, [socket, allowCommand, command, user.name]);

  return (
    <S.DirectionalButton
      disabled={
        status !== 'P' ||
        !plays ||
        feedback !== '' ||
        !seconds ||
        !videoIsLoaded ||
        !isActive
      }
      {...(isMobileDevice() && {
        onTouchStart: sendCommand,
        onTouchEnd: cancelCommand,
      })}
      {...(!isMobileDevice() && {
        onMouseDown: sendCommand,
        onMouseUp: cancelCommand,
      })}
    >
      {children}
    </S.DirectionalButton>
  );
}

export default DirectionalButton;
