import { Socket } from 'socket.io-client';
import { gameStatus } from './types';
import userService from '../services/userService';
import { UserProps } from '../context/types';

export const listeners = (
  socket: Socket,
  setStatus: (status: 'O' | 'W' | 'R' | 'P') => void,
  setFeedback: React.Dispatch<
    React.SetStateAction<'finished' | 'error' | 'timeover' | ''>
  >,
  setPlays: React.Dispatch<React.SetStateAction<number>>,
  userId: string,
  updateUserInfo: <T extends keyof UserProps>(
    fieldName: T,
    newValue: UserProps[T]
  ) => void,
  startGame: () => void
) => {
  socket.on('ping', (arg) => {
    console.log(arg);
  });

  socket.on('connect', () => {
    console.log('connected');
  });

  socket.on('ready_to_play', () => {
    setStatus('P');
  });

  socket.on('disconnect', () => {
    setStatus('O');
  });

  socket.on('machine_disconnected', () => {
    setStatus('O');
    setFeedback('error');

    setTimeout(() => {
      userService.getWallet(userId).then((response) => {
        updateUserInfo('amount', response.available_money);
        updateUserInfo('plushQtd', response.plush_to_recover);
        updateUserInfo('victoryAmount', response.victory_money);
      });
      setFeedback('');
    }, 3500);
  });

  socket.on('disconnection_timeout', () => {
    setStatus('O');
    setFeedback('timeover');

    userService.getWallet(userId).then((response) => {
      updateUserInfo('amount', response.available_money);
      updateUserInfo('plushQtd', response.plush_to_recover);
      updateUserInfo('victoryAmount', response.victory_money);
    });

    setTimeout(() => {
      setFeedback('');
    }, 3500);
  });

  socket.on('game_status', (data: gameStatus) => {
    setPlays(data.remaining_moves);
    if (data.is_game_finished) {
      setStatus('O');
      setFeedback('finished');

      userService.getWallet(userId).then((response) => {
        updateUserInfo('amount', response.available_money);
        updateUserInfo('plushQtd', response.plush_to_recover);
        updateUserInfo('victoryAmount', response.victory_money);
      });

      setTimeout(() => {
        setFeedback('');
      }, 3300);
    } else {
      startGame();
    }
  });

  socket.on('payment', () => {});
};
